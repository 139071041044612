<template>
  <div class="onr-body__find-instructors__banner">
    <h2 v-if="authUser" id="find-students" class="find-instructors--heading onr-body__find-instructors--heading">
      Find my student
    </h2>
  </div>
  <div class="onr-body__find-instructors__container-view">
    <div class="onr-body__find-instructors__panel--filter">
      <div class="filter-container">
        <div class="filter__title">
          <span class="input__label">Subjects</span>
          <span class="accordion-chevron__down" :class="{ 'accordion-chevron__up' : toggleContent}"
                @click="toggleFilter('subject')">
						<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none">
							<path d="M19.1416 9.92896L12.7776 16.2929C12.3871 16.6834 11.7539 16.6834 11.3634 16.2929L4.99946 9.92895"
                    stroke="#000000" stroke-linecap="round"/>
						</svg>
					</span>
        </div>
        <div class="filter-checkbox" :class="filterClasses('subject')">
          <Checkbox
              class="subject-checkbox filter"
              v-for="(subject, index) in subjects"
              :key="`subject-${subject.key}-${index}`"
              v-model="subject.checked"
              :name="subject.label"
              :value="subject.value"
              @checked="onCheckboxChecked(subject, 'subject')"
              @unchecked="onCheckboxUnchecked(subject, 'subject')"
          ></Checkbox>
        </div>
      </div>
      <div class="filter-container">
        <div class="filter__title">
          <span class="input__label">Available Days</span>
          <span class="accordion-chevron__down" :class="{ 'accordion-chevron__up' : toggleContent}"
                @click="toggleFilter('day')">
						<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none">
							<path d="M19.1416 9.92896L12.7776 16.2929C12.3871 16.6834 11.7539 16.6834 11.3634 16.2929L4.99946 9.92895"
                    stroke="#000000" stroke-linecap="round"/>
						</svg>
					</span>
        </div>
        <div class="filter-checkbox" :class="filterClasses('day')">
          <Checkbox
              class="subject-checkbox filter"
              v-for="(day, index) in days"
              :key="`day-${day.key}-${index}`"
              v-model="day.checked"
              :name="day.label"
              :value="day.value"
              @checked="onCheckboxChecked(day, 'day')"
              @unchecked="onCheckboxUnchecked(day, 'day')"
          ></Checkbox>
        </div>
      </div>
      <div class="filter-container">
        <div class="filter__title">
          <span class="input__label">Available Time</span>
          <span class="accordion-chevron__down" :class="{ 'accordion-chevron__up' : toggleContent}"
                @click="toggleFilter('time')">
						<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none">
							<path d="M19.1416 9.92896L12.7776 16.2929C12.3871 16.6834 11.7539 16.6834 11.3634 16.2929L4.99946 9.92895"
                    stroke="#000000" stroke-linecap="round"/>
						</svg>
					</span>
        </div>
        <div class="filter-checkbox" :class="filterClasses('time')">
          <Checkbox
              class="subject-checkbox filter"
              v-for="(time, index) in timeRange"
              :key="`time-${time.key}-${index}`"
              v-model="time.checked"
              :name="time.label"
              :value="time.value"
              @checked="onCheckboxChecked(time, 'time')"
              @unchecked="onCheckboxUnchecked(time, 'time')"
          ></Checkbox>
        </div>
      </div>
    </div>
    <div class="onr-body__find-instructors">
      <div class="onr-body__find-instructors-container">
        <div class="onr-body__find-instructors__panel--results">
          <div class="onr-body__find-instructors__panel--results-container">
            <div v-if="!authUser" class="onr-none-found">
              <h2>You must <a class="login-link" @click="openLogin()">login</a> to find a match.</h2>
            </div>
            <template v-for="(instructor, index) in filteredInstructors" :key="index">
              <div
                  class="onr-body__find-instructors__panel--card"
              >
                <div class="onr-body__find-instructors__panel--image">
                  <!-- <img class="panel-box__img" :src="profileImage"/> -->
                  <img class="panel-box__img" src="../../assets/profile.jpeg"/>
                </div>
                <div class="onr-body__find-instructors__panel--box">
                  <div class="onr-instructor__container">
                    <h2 class="onr-instructor__name">{{ instructor.firstName + " " + instructor.lastName }}</h2>
                    <h3 class="onr-instructor__subject">{{ subjectsByTeacher(instructor) }}</h3>
                    <div class="onr-instructor__info-container">
                      <span class="onr-instructor__country">
<img class="info-icon" src="../../assets/location.svg"/>
                        {{ getNameByCode(instructor.country) }}
                      </span>
                      <span class="onr-instructor__occupation">
												<img class="info-icon" src="../../assets/business.svg"/>
												{{ instructor.occupation }}</span>
                      <span class="onr-instructor__academicfields">
												<img class="info-icon" src="../../assets/academic.svg"/>
												{{ instructor.academicFields }}</span>
                      <span class="onr-instructor__interests">
												<img class="info-icon" src="../../assets/star.svg"/>
												{{ instructor.interests }}
											</span>
                      <span class="onr-instructor__availability">
												<img class="info-icon" src="../../assets/calendar.svg"/>
												{{ formattedAvailabilities(instructor) }}
											</span>
                    </div>
                  </div>

                  <div class="panel-box__schedule">
                    <router-link class="onr-instructor__book" :to="{ path: `/student/${instructor.uid}` }"
                                 @click="onTeacherSelect(instructor.uid)">
                      Select
                      <!-- <img class="panel-box__schedule-link--arrow" src="/arrow-right.svg" /> -->
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoginOverlay :showModal="showLoginModal" @close="close"></LoginOverlay>
</template>

<script>
import {findIndex, find, filter, map, union} from 'lodash';
import Checkbox from '../../components/checkbox.vue';
import LoginOverlay from '../../components/overlays/loginOverlay.vue';
import {auth} from '@/firebase.config';
//import {teachers} from "@/api";
import {ref, watchEffect} from "vue";
import {getProfileImage} from "@/api/image";
import {students} from "@/api/students";
import countries from '@/data/countries.json';

export default {
  name: 'FindInstructors',
  components: {
    LoginOverlay,
    Checkbox,
  },
  setup() {
    const instructors = ref([]);
    let filteredInstructors = ref([]);

    watchEffect(async () => {
      students().then((resp) => {
        instructors.value = resp;
        filteredInstructors.value = resp;
        console.log("...instructors", instructors.value)
      }).catch((error) => {
        console.log(error);
      });
    });

    return {
      instructors,
      filteredInstructors
    }
  },
  data() {
    return {
      showLoginModal: false,
      profileImage: '',
      days: [
        {
          label: 'Monday',
          value: 'Mon',
          checked: true,
        },
        {
          label: 'Tuesday',
          value: 'Tue',
          checked: true,
        },
        {
          label: 'Wednesday',
          value: 'Wed',
          checked: true,
        },
        {
          label: 'Thursday',
          value: 'Thu',
          checked: true,
        },
        {
          label: 'Friday',
          value: 'Fri',
          checked: true,
        },
        {
          label: 'Saturday',
          value: 'Sat',
          checked: true,
        },
        {
          label: 'Sunday',
          value: 'Sun',
          checked: true,
        },
      ],
      timeRange: [
        {
          label: '6:00',
          value: '6:00',
          checked: true,
        },
        {
          label: '7:00',
          value: '7:00',
          checked: true,
        },
        {
          label: '8:00',
          value: '8:00',
          checked: true,
        },
        {
          label: '9:00',
          value: '9:00',
          checked: true,
        },
        {
          label: '10:00',
          value: '10:00',
          checked: true,
        },
        {
          label: '11:00',
          value: '11:00',
          checked: true,
        },
        {
          label: '12:00',
          value: '12:00',
          checked: true,
        },
        {
          label: '13:00',
          value: '13:00',
          checked: true,
        },
        {
          label: '14:00',
          value: '14:00',
          checked: true,
        },
        {
          label: '15:00',
          value: '15:00',
          checked: true,
        },
      ],
      timeSlots: [
        {
          label: 'AM',
          checked: true
        },
        {
          label: 'PM',
          checked: true
        }
      ],
      subjects: [
        {
          label: 'English (SBS)',
          value: 'sbs',
          checked: true,
        },
        {
          label: 'LBL(Coding)',
          value: 'lbl_coding',
          checked: true,
        },
        {
          label: 'LBL(MS Office)',
          value: 'lbl_office',
          checked: true,
        },
        {
          label: 'Korean (한벗)',
          value: 'han',
          checked: true,
        }
      ],
      level: [
        'Beginner',
        'Intermediate',
        'Advanced'
      ],
      filterStatus: [
        {
          label: 'Subject',
          value: 'subject',
          open: true,
        },
        {
          label: 'Available Days',
          value: 'day',
          open: true,
        },
        {
          label: 'Available Time',
          value: 'time',
          open: true,
        },
      ],
    };
  },

  mounted() {

    window.scrollTo({top: 0, behavior: 'smooth'});
    // TODO Temp
    getProfileImage(auth.currentUser.uid).then((response) => {
      if (response !== '') {
        this.profileImage = response;
      } else {//temp
        this.profileImage = 'https://firebasestorage.googleapis.com/v0/b/mystic-tempo-383023.appspot.com/o/public%2Fprofile_4945750.png?alt=media';
        console.log(this.profileImage)
      }
    }).catch((error) => {
      console.log("Test")
      console.log(error);
    });
  },
  computed: {
    authUser() {
      return auth.currentUser;
    },
    authenticated() {
      return localStorage.getItem('authenticated');
    },
  },
  methods: {

    getNameByCode(countryCode) {
      const country = countries.find(country => country.code === countryCode);
      return country ? country.name : null;
    },
    uniqueInterests(instructor) {
      return union(instructor.interests);
    },
    filterClasses(type) {
      const currentType = find(this.filterStatus, (item) => item.value === type);
      return currentType.open ? '' : 'filter--close';
    },
    toggleFilter(type) {
      const currentType = find(this.filterStatus, (item) => item.value === type);
      currentType.open = currentType.open ? false : true;
      const index = findIndex(this.filterStatus, {value: type});
      this.filterStatus.splice(index, 1, {label: currentType.label, value: type, open: currentType.open});
    },
    formattedAvailabilities(instructor) {
      function isDaylightSavingTime() {
        const now = new Date();

        // Get the timezone offset in minutes (difference between UTC and local time)
        const jan = new Date(now.getFullYear(), 0, 1).getTimezoneOffset(); // January 1st (usually standard time)
        const jul = new Date(now.getFullYear(), 6, 1).getTimezoneOffset(); // July 1st (usually daylight saving time)

        // Compare January and July offsets to current offset
        const currentOffset = now.getTimezoneOffset();

        // If the current offset is less than January's, DST is in effect
        return currentOffset < Math.max(jan, jul);
      }
      const availabilities = JSON.parse(instructor.availableTimes);
      const adjustedAvailabilities = availabilities.map(availability => {
        // Split the availability string into day and time parts
        const [day, time] = availability.split(' ');

        // Create a Date object for the given day and time
        const availabilityDate = new Date(`2023-01-01T${time}:00`);
        //  % TODO daylight savings time
        // Deduct 300 minutes from the time part
        if (instructor.country === 'IL' || instructor.country === 'PS') {
          availabilityDate.setMinutes(availabilityDate.getMinutes() - 420);
        } else {
          if (isDaylightSavingTime()) {
            availabilityDate.setMinutes(availabilityDate.getMinutes() - 420);
          } else {
            availabilityDate.setMinutes(availabilityDate.getMinutes() - 480);
          }
        }
        const adjustedAvailability = `${day} ${availabilityDate.getHours()}:00`;
        return adjustedAvailability;
      });

      return adjustedAvailabilities.join(',');
    },
    subjectsByTeacher(instructor) {
      let subject = '';
      subject = (instructor.sbs ? 'English ' : '') + (instructor.lbl_coding ? 'Coding ' : '') + (instructor.lbl_office ? 'Office ' : '') + (instructor.han ? 'Korean' : '');
      return subject;
    },
    filterSubjects() {
      const filteredSubjectResults = [];
      filter(this.instructors, (instructor) => {
        //console.log('>>>>> instructor name: ', instructor.firstName);

        // subjects
        map(this.subjects, (subject) => {
          subject.value === 'lbl_coding' && subject.checked && instructor.lbl_coding === 1 ? filteredSubjectResults.push(instructor) : '';
          subject.value === 'lbl_office' && subject.checked && instructor.lbl_office === 1 ? filteredSubjectResults.push(instructor) : '';
          subject.value === 'sbs' && subject.checked && instructor.sbs === 1 ? filteredSubjectResults.push(instructor) : '';
          subject.value === 'han' && subject.checked && instructor.han === 1 ? filteredSubjectResults.push(instructor) : '';
        });
      });
      //console.log("...subject", filteredSubjectResults)
      const filteredDayResults = [];
      filter(union(filteredSubjectResults), (instructor) => {
        // days
        const availabilities = instructor.availableTimes;
        map(this.days, (day) => {
          //console.log('>>>day: ', day);
          const regex = new RegExp(day.value, 'i');
          const isAvailableDay = regex.test(availabilities);
          //console.log('.isAvailableDay: ', isAvailableDay);

          day.value === 'Mon' && day.checked && isAvailableDay ? filteredDayResults.push(instructor) : '';
          day.value === 'Tue' && day.checked && isAvailableDay ? filteredDayResults.push(instructor) : '';
          day.value === 'Wed' && day.checked && isAvailableDay ? filteredDayResults.push(instructor) : '';
          day.value === 'Thu' && day.checked && isAvailableDay ? filteredDayResults.push(instructor) : '';
          day.value === 'Fri' && day.checked && isAvailableDay ? filteredDayResults.push(instructor) : '';
          day.value === 'Sat' && day.checked && isAvailableDay ? filteredDayResults.push(instructor) : '';
          day.value === 'Sun' && day.checked && isAvailableDay ? filteredDayResults.push(instructor) : '';
        });
      });
      console.log("...day", filteredDayResults)
      const filteredTimeResults = [];
      filter(union(filteredDayResults), (instructor) => {
        // time
        //const availabilities = instructor.availableTimes;
        map(this.timeRange, (time) => {
          //console.log('>>>time: ', time);
          const regex = new RegExp(time.value, 'i');
          const isAvailableTime = regex.test(this.formattedAvailabilities(instructor))

          //console.log('.isAvailableTime: ', isAvailableTime);
          time.value === '6:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '7:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '8:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '9:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '10:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '11:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '12:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '13:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '14:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
          time.value === '15:00' && time.checked && isAvailableTime ? filteredTimeResults.push(instructor) : '';
        });
      });
      //  console.log("...union", union(filteredTimeResults))
      return union(filteredTimeResults);
    },
    filterDays() {
      const filteredResults = [];
      filter(this.instructors, (instructor) => {
        const availabilities = instructor.availableTimes;
        //console.log('>>>>> instructor name: ', instructor.firstName);
        map(this.days, (day) => {
          //console.log('>>>day: ', day);
          const regex = new RegExp(day.value, 'i');
          const isAvailableDay = regex.test(availabilities);
          //console.log('.isAvailableDay: ', isAvailableDay);

          day.value === 'mon' && day.checked && isAvailableDay ? filteredResults.push(instructor) : '';
          day.value === 'tue' && day.checked && isAvailableDay ? filteredResults.push(instructor) : '';
          day.value === 'wed' && day.checked && isAvailableDay ? filteredResults.push(instructor) : '';
          day.value === 'thu' && day.checked && isAvailableDay ? filteredResults.push(instructor) : '';
          day.value === 'fri' && day.checked && isAvailableDay ? filteredResults.push(instructor) : '';
          day.value === 'sat' && day.checked && isAvailableDay ? filteredResults.push(instructor) : '';
          day.value === 'sun' && day.checked && isAvailableDay ? filteredResults.push(instructor) : '';
        });
      });
      return union(filteredResults);
    },
    onCheckboxChecked(val, type) {
      if (type === 'subject') {
        const currentType = find(this.subjects, (item) => item.value === val.value);
        const index = findIndex(this.subjects, {value: val.value});
        this.subjects.splice(index, 1, {label: currentType.label, value: currentType.value, checked: true});
        this.filteredInstructors = this.filterSubjects();
      }

      if (type === 'day') {
        const currentType = find(this.days, (item) => item.value === val.value);
        const index = findIndex(this.days, {value: val.value});
        this.days.splice(index, 1, {label: currentType.label, value: currentType.value, checked: true});
        this.filteredInstructors = this.filterSubjects();
      }

      if (type === 'time') {
        const currentType = find(this.timeRange, (item) => item.value === val.value);
        const index = findIndex(this.timeRange, {value: val.value});
        this.timeRange.splice(index, 1, {label: currentType.label, value: currentType.value, checked: true});
        this.filteredInstructors = this.filterSubjects();
      }
    },
    onCheckboxUnchecked(val, type) {
      if (type === 'subject') {
        const currentType = find(this.subjects, (item) => item.value === val.value);
        const index = findIndex(this.subjects, {value: val.value});
        this.subjects.splice(index, 1, {label: currentType.label, value: currentType.value, checked: false});
        this.filteredInstructors = this.filterSubjects();
      }

      if (type === 'day') {
        const currentType = find(this.days, (item) => item.value === val.value);
        const index = findIndex(this.days, {value: val.value});
        this.days.splice(index, 1, {label: currentType.label, value: currentType.value, checked: false});
        this.filteredInstructors = this.filterSubjects();
      }

      if (type === 'time') {
        const currentType = find(this.timeRange, (item) => item.value === val.value);
        const index = findIndex(this.timeRange, {value: val.value});
        this.timeRange.splice(index, 1, {label: currentType.label, value: currentType.value, checked: false});
        this.filteredInstructors = this.filterSubjects();
      }
    },
    openLogin() {
      this.showLoginModal = true;
    },
    onTeacherSelect(teacherId) {
      console.log('...teacherId: ', teacherId);
      this.$router.push({
        name: 'Student',
        params: {id: teacherId},
      });
    },
    close() {
      this.showLoginModal = false;
    },
  },
  watch: {
    authUser(val) {
      console.log('..val: ', val);
      if (val) {
        console.log('...user is now loggede in!');
        window.location.reload();
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../utils/_colors.scss';

.subject-checkbox {
  &.filter {
    text-align: left;
    padding-left: 0;
    margin-top: 0.5rem;
    margin-left: 1rem;

    @media only screen and (max-width: 550px) {
      margin-left: 0;
    }

    .checkbox-checkmark {
      left: unset;
      right: 0;
    }
  }
}

.onr-none-found {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 70vh;
  width: 100%;
  margin-top: 5rem;

  .login-link {
    cursor: pointer;
    text-decoration: underline;
  }
}

.onr-instructor {
  &__info-container {
    display: flex;
    flex-direction: column;
    margin: 0 3.5rem;
    height: 150px;
    overflow: scroll;
    max-width: 400px;
    padding: 0 2rem;

    @media only screen and (max-width: 550px) {
      margin: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  &__name {
    color: $blue-1;
    font-size: 30px;
    font-weight: 900;
    margin: 10px 0 0;
    text-transform: uppercase;
  }

  &__subject {
    color: $blue-2;
    font-size: 1em;
    font-weight: 400;
    margin: 0 0 15px;
  }

  &__occupation, &__interests, &__academicfields, &__availability, &__country, &__residence {
    font-size: 13px;
    text-align: left;

    .info-icon {
      width: 15px;
      vertical-align: sub;
    }
  }

  &__book {
    background-color: $blue-1;
    color: white;
    padding: 5px 40px;
    text-decoration: none;
    text-transform: uppercase;

    &:visited {
      color: white;
      text-decoration: none;
    }

    &:hover {
      color: white;
      text-decoration: none;
    }

    &:active {
      color: white;
      text-decoration: none;
    }
  }
}


.panel {
  &-box {
    &__top {
      display: flex;

      .panel-box {
        &__subject {
          margin-left: auto;

          &-label {
            color: $grey-1;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 800;
            vertical-align: sub;
          }
        }

        &__level {
          display: flex;

          &-label {
            color: $grey-1;
            font-size: 13px;
            font-weight: 500;
            margin: 7px 0 0 8px;
            text-transform: capitalize;
          }
        }
      }
    }

    &__schedule {
      margin: 5px;

      &-link {
        color: black;
        cursor: pointer;
        font-weight: 400;
        text-decoration: none;

        &:link {
          color: black;
          text-decoration: none;
        }

        &:visited {
          color: black;
          text-decoration: none;
        }

        &:hover {
          color: black;
          text-decoration: none;
        }

        &:active {
          color: black;
          text-decoration: none;
        }

        &--arrow {
          vertical-align: bottom;
        }
      }

    }

    &__img {
      width: 100%;
    }

    &__course {
      padding: 14px 10px 0px 10px;
      min-height: 82.8px;
      text-align: left;

      &-title {
        font-size: 17px;
        font-weight: 800;
        line-height: 1.2;
        margin: 0;
      }
    }

    &__name {
      padding: 0;

      &-label {
        color: $grey-1;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }

  &-checkbox {
    display: block;
    text-align: left;
    margin: 0 0 5px 30px;
  }

  &-filter {
    &__button {
      background-color: $green-1;
      border: none;
      color: white;
      cursor: pointer;
      padding: 8px 20px;

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 42px 0 10px;
      }
    }

    &__reset {
      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 6px 0 26px;
      }

      &-link {
        color: $grey-1;
        cursor: pointer;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

.onr-body {
  &__find-instructors {
    margin: 0 1rem 1rem;
    text-align: center;
    width: 100%;

    &__banner {
      display: flex;
      justify-content: center;
      background-color: $blue-1;
      color: white;
      margin-bottom: 4rem;
      margin-top: 105.76px;

      @media only screen and (max-width: 550px) {
        margin-bottom: 1rem;
      }
    }

    &--heading {
      font-size: 3em;
      padding: 1rem;
    }

    &__container-view {
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: 630px) {
        flex-direction: column;
      }
    }

    &__panel {
      &--box {
        width: 100%;
        margin-bottom: 2rem;
      }

      &--image {
        height: 250px;
        overflow: hidden;

        .panel-box__img {
          object-fit: cover;
          width: 100%;
          border-radius: 10px;
        }
      }

      &--results {
        width: 100%;

        &-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;

          @media only screen and (max-width: 1250px) {
            .onr-body__find-instructors__panel--card {
              width: 46%;
            }
          }

          @media only screen and (max-width: 950px) {
            .onr-body__find-instructors__panel--card {
              width: 95%;
            }
          }

          @media only screen and (max-width: 590px) {
            .onr-body__find-instructors__panel--card {
            }
          }

        }
      }

      // &--card {
      // 	background-color: $neutral-1;
      // 	border: 1px solid $neutral-2;
      // 	border-radius: 10px;
      // 	box-shadow: 0 1px 3px rgba(83, 91, 96, 0.23);
      // 	margin: 0 20px 20px 0;
      // 	width: 26%;
      // }

      &--card {
        display: flex;
        flex-direction: column;
        width: 30%;
        align-items: center;
        padding: 0;
        border: 1px solid $grey-4;
        border-radius: 10px;
        margin-right: 1rem;
        margin-bottom: 1rem;

      }

      &--filter {
        height: fit-content;
        margin: 0 2rem 2rem 3rem;
        white-space: nowrap;
        text-align: left;

        background-color: $beige-1;
        border-radius: 5px;
        padding: 2rem;

        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 550px) {
          margin: 1rem;
          padding: 1rem 0 1rem 1rem;
        }

        .filter-container {
          font-size: 1em;

          .filter-checkbox {
            .filter {
              font-size: 0.8em;

              @media only screen and (max-width: 550px) {
                margin-right: 1.5rem;
              }
            }

            &.filter--close {
              display: none;
            }
          }

          .filter__title {
            display: flex;

            .accordion-chevron__down {
              margin-left: auto;
              order: 2;

              @media only screen and (max-width: 550px) {
                margin-right: 1.2rem;
              }
            }
          }
        }

        .filter-checkbox {
          margin: 1rem 0 2rem;
        }

        .dropdown--subjects {
          width: 19.1em;
          height: 2.5em;
        }

        &-box {
          width: 200px;

          &.onr-subjects {
            text-align: left;
          }

          &.onr-available-days, .onr-available-times {
            text-align: left;
          }
        }
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
    }

    &--heading {
      font-size: 3em;

      @media only screen and (max-width: 500px) {
        font-size: 2em;
      }
    }
  }
}

.onr-checkbox {
  &__span {
    color: $black-1;
    font-size: 13px;
    font-weight: 500;
  }

  &__container {
    input {
      vertical-align: middle;
    }
  }
}

.onr-heading {
  &__subjects, &__availability, &__available-time {
    color: $grey-2;
    font-size: 15px;
    font-weight: 700;
    margin: 5px 0 10px 33px;
    text-align: left;
  }

  &__availability, &__available-time {
    margin-top: 20px;
  }
}
</style>