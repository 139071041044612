import axios from 'axios';
import { config } from '../config';
import {auth} from "@/firebase.config";

export async function createUser(data) {
  const host = `${config.service.host}/api`;

  const response = await axios.post(
    `${host}/user`,
    {
      data
    },
    {
      headers: {
        //Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}


export async function getUser() {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
        `${host}/user/` + auth.currentUser.uid,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}

export async function updateUser(data) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.put(
        `${host}/user/` + auth.currentUser.uid,
        {
            data
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}


