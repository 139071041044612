<template>
  <div v-if="showModal" class="onr-login">
    <div class="onr-login__modal" :class="loginClasses()" role="dialog">
      <div v-show="page === 0" class="onr-login__modal-container">
        <div class="onr-login__modal-container--left">
          <img class="login-img" src="../../assets/Login_v2_03.jpg"/>
        </div>
        <div v-show="login === 0" class="onr-login__modal-container--right">
          <a class="login-close" @click="closeModal()">
            <svg height="15px" id="Layer_1" viewBox="0 0 512 512" width="15px">
              <path
                  d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/>
            </svg>
          </a>
          <h1 class="login-heading">Login</h1>
          <input
              class="login-input__email"
              :class="emailClasses()"
              ref="email-login"
              inputType="single-line"
              v-model="submission.email"
              field-id="email"
              placeholder="Username or email"
              @input="validateEmail()"
          />
          <span class="email-error" v-if="showEmailError">Must enter a valid email address.</span>
          <input
              class="login-input__password"
              :class="passwordClasses()"
              ref="password-login"
              inputType="single-line"
              v-model="submission.password"
              field-id="password"
              placeholder="Password"
              type="password"
              @input="validatePassword()"
          />
          <span class="password-error" v-if="showPasswordError">Password must be at least eight characters, at least one uppercase letter, one lowercase, one number, and one special character.</span>
          <!-- <div class="login-actions">
            <Checkbox
              class="login-remember"
              v-model="rememberMe"
              name="Remember me"
              @change="onRememberMeChecked()"
            ></Checkbox>
            <span class="login-lost-password">Lost your password?</span>
          </div> -->
          <div class="login-actions">
            <a class="login-lost-password" @click="resetPassword()">Forgot your password?</a>
          </div>
          <button class="button-login" :class="loginButtonClasses()" @click="onLoginSubmit()">Login</button>
          <span class="no-account-text">No account yet?</span>
          <button class="button-create" @click="showCreateAccountOverlay()">Create Account</button>
        </div>
        <div v-show="login === 1" class="referral-container onr-login__modal-container--right">
          <a class="login-close" @click="closeModal()">
            <svg height="15px" id="Layer_1" viewBox="0 0 512 512" width="15px">
              <path
                  d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/>
            </svg>
          </a>
          <ReferralCodePage @verify="verify"></ReferralCodePage>
        </div>
      </div>
      <CreateAccountForm v-show="page === 1" @close="closeModal()"></CreateAccountForm>
    </div>
  </div>
</template>

<script>
//import { find } from 'lodash';
// import Checkbox from '../checkbox.vue'

import {isEmpty} from 'lodash';
import {getUser} from '@/api';
import sampleLogins from '../../data/sampleLogins.json';
import users from '../../data/instructors.json';
import ReferralCodePage from '../../views/LogIn/ReferralCodePage.vue';
import CreateAccountForm from '../../views/LogIn/CreateAccountForm.vue';
import {auth, sendPasswordResetEmail} from "@/firebase.config";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'login-overlay',
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      showEmailError: false,
      showPasswordError: false,
      verified: false,
      login: 0,
      page: 0,
      showCreateAccount: false,
      rememberMe: false,
      submission: {
        email: '',
        password: '',
      }
    };
  },
  mounted() {
    this.logins;
    this.users;
  },
  components: {
    ReferralCodePage,
    CreateAccountForm,
    // Checkbox,
  },
  computed: {
    logins() {
      return sampleLogins;
    },
    users() {
      return users;
    },
  },
  methods: {
    resetPassword() {
      if (!this.submission.email) {
        alert("Please type in a valid email address.");
        return;
      }
      sendPasswordResetEmail(auth, this.submission.email).then(function () {
        alert("Password reset email sent.");
      }).catch(function (error) {
        alert(error.message);
      });

    },
    clearError(type) {
      if (type === 'email') {
        this.showEmailError = false;
      } else if (type === 'password') {
        this.showPasswordError = false;
      }
    },
    passwordClasses() {
      return this.showPasswordError ? 'has-password-error' : '';
    },
    emailClasses() {
      return this.showEmailError ? 'has-email-error' : '';
    },
    showCreateAccountOverlay() {
      this.login = 1;
    },
    loginButtonClasses() {
      return !this.showEmailError && !this.showPasswordError ? '' : 'login-disabled';
    },
    loginClasses() {
      return this.page === 1 ? 'full-overlay' : '';
    },
    closeModal() {
      localStorage.removeItem('code');
      localStorage.removeItem('role');
      this.login = 0;
      this.$emit('close');
    },
    verify() {
      this.login = null;
      this.page = 1;
    },
    async validatedUser() {
      await getUser();
    },
    validateEmail() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValidEmail = regex.test(this.submission.email);
      if (!isEmpty(this.submission.email) && !isValidEmail) {
        console.log('...jere');
        this.showEmailError = true;
      } else {
        console.log('...jere else');
        this.showEmailError = false;
      }
    },
    validatePassword() {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/;
      const isValidPassword = regex.test(this.submission.password);
      if (!isEmpty(this.submission.password) && !isValidPassword) {
        this.showPasswordError = true;
      } else {
        this.showPasswordError = false;
      }
    },
    onLoginSubmit() {
      this.validateEmail();
      this.validatePassword();
      this.$store.dispatch('authStore/logIn', this.submission).then(() => {
        this.$emit('close');

        // TODO -- use watch or onMount
        window.location.reload();
      }).catch((error) => {
        console.log(error);
      });
    }
  },
  watch: {},
}
</script>

<style lang="scss">
@import '../../utils/_colors.scss';

.onr-login {
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  @media only screen and (max-width: 650px) {
    .onr-login__modal {
      width: 20em;
    }
  }

  &__modal {
    display: flex;
    position: absolute;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    width: 39em;
    height: 30em;
    background: #FFF;
    z-index: 999;
    transform: none;

    &.full-overlay {
      justify-content: center;
      width: 90%;
      height: 40em;
      overflow: scroll;
    }

    &-container {
      display: flex;
      position: absolute;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      text-align: center;
      width: 39em;
      height: 30em;
      background: #FFF;
      z-index: 999;
      transform: none; //TODO: clean this up

      @media only screen and (max-width: 650px) {
        .onr-login__modal-container--left {
          display: none;
        }
        width: 20em;
      }

      &--left {
        height: inherit;

        .login-img {
          max-width: 20em;
          height: inherit;
        }
      }

      &--left, &--right {
        flex-direction: column;
      }

      &--right {
        &.referral-container {
          display: flex;
          align-items: center;
        }

        .password-error, .email-error {
          color: red;
          font-size: 10px;
          margin-bottom: 10px;
          text-align: left;
          margin-left: 1rem;
        }

        display: flex;
        flex-direction: column;
        width: inherit;

        .no-account-text {
          font-size: 15px;
          text-align: left;
          margin: 40px 0 0 25px;
        }

        .button {
          &-login, &-create {
            background-color: $blue-1;
            border-radius: 25px;
            border: unset;
            height: 40px;
            margin: 5px 20px;
            font-size: 15px;
            text-transform: uppercase;
            color: white;
            cursor: pointer;
          }
        }

        .login {
          &-disabled {
            background-color: grey;
            cursor: not-allowed;
          }

          &-heading {
            font-size: 38px;
            text-transform: uppercase;
            text-align: left;
            margin: 20px 0 0 20px;
            font-weight: 200;
          }

          &-input {
            &__email, &__password {
              border: 1px solid $grey-4;
              height: 35px;
              padding-left: 0.7rem;
              margin-bottom: 1rem;
              margin-left: 1rem;
              width: 250px;
            }

            &__password {
              &.has-password-error {
                margin-bottom: 5px;
              }
            }

            &__email {
              &.has-email-error {
                margin-bottom: 5px;
              }
            }
          }

          &-close {
            right: 11px;
            position: absolute;
            top: 8px;
            cursor: pointer;

            svg {
              fill: grey;
            }
          }

          &-actions {
            margin: 8px 25px 10px 20px;

            .login {
              &-remember {
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 600;
                float: left;
              }

              &-lost-password {
                text-decoration: underline;
                font-size: 13px;
                float: right;
                cursor: pointer;
              }
            }
          }
        }

        .text-input__wrapper {
          margin: 10px 20px;
          width: auto;

          .text-input {
            width: 90%;
          }
        }
      }
    }
  }
}

</style>